// Import our CSS
import '@/css/app.pcss';

import '@/js/src-prev-build/script.js'

// App main
const main = async () => {
  console.log('main()')

    // Mount the app
    const body = document.body;

    return body;
};

// Execute async function
main().then( (body) => {
});
